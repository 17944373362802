import { PATH } from '@/constants/path';
import type { CustomRouteObject } from '../CustomRouteObject';


const ApiRedirect = () => {
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    window.location.replace(`${apiBaseUrl}docs/index.html`);
    return null; 
};
const ApiDocRoute: CustomRouteObject = {
    path : PATH.API_DOC,
    element: <ApiRedirect />,
};


export default ApiDocRoute;